import React from "react";
import styled from "styled-components";
import MuslimHandsLogo from "@assets/charityLogos/muslim-hands.png";
import NZFLogo from "@assets/charityLogos/nzf.png";
import IslamicReliefLogo from "@assets/charityLogos/islamic-relief.png";
import FundersInGoodLogo from "@assets/charityLogos/funders-in-good.png";
import { MEDIA_QUERIES, Text } from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  & > [type="P-12"] {
    position: absolute;
    right: 55px;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
`;

const Logo = styled.img<{ index: number }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  z-index: ${({ index: zIndex }) => zIndex + 1};
  box-shadow: 0px 1px 6px rgb(52 111 180 / 15%);
  position: absolute;
  left: ${({ index }) => `${index * 40}px`};
`;

export const CharitiesLogos: React.FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });

  return (
    <Container>
      <LogosContainer>
        {[IslamicReliefLogo, MuslimHandsLogo, NZFLogo, FundersInGoodLogo].map(
          (img, idx) => (
            <Logo key={idx} src={img} index={idx} />
          )
        )}
      </LogosContainer>
      {!isMobile && (
        <Text type="P12" color="BLUE900">
          Islamic Relief, Muslim Hands, Muslim Aid, NZF & Funders in Good.
        </Text>
      )}
    </Container>
  );
};
